import React, { useEffect } from 'react';

import { BaseTemplate } from '@templates';
import { SEO } from '@components';

import '@style/base.scss';
import '@style/grid.scss';

import './index.scss';

const CovidPage = () => {
  useEffect(() => {
    const divElement = document.getElementById('viz1586857247739');

    const vizElement = divElement.getElementsByTagName('object')[0];

    vizElement.style.width = '1002px';
    vizElement.style.height = '1930px';

    const scriptElement = document.createElement('script');

    scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';

    vizElement.parentNode.insertBefore(scriptElement, vizElement);
  }, []);

  return (
    <BaseTemplate>
      <SEO
        title="COVID-19 Philippines Tracker"
        description="We are one with our community in hosting this COVID-19 tracker to help you monitor the spread of the disease in the Philippines."
      />
      <div className="covid-header">
        <h4>
          <span>
            {
              'We are one with our community in hosting this COVID-19 tracker to help you monitor the spread of the disease in the Philippines. For up to date health advisories, please visit the '
            }
          </span>
          <a href="https://www.doh.gov.ph" target="_blank" rel="noopener noreferrer">
            Department of Health
          </a>.
        </h4>
      </div>
      <div className="page-section covid-tracker-section">
        <div
          className="tableauPlaceholder"
          id="viz1586857247739"
          style={{
            borderRadius: '4px',
            border: '1px solid #c7c7c7',
            position: 'relative',
            margin: '0 auto',
            overflowY: 'scroll'
          }}
        >
          <noscript>
            <a href="#">
              <img
                alt=" "
                src="https://public.tableau.com/static/images/Co/Covid-19PhilippinesFlattenthecurvebreakthechain/Covid-19PHDashboard/1_rss.png"
                style={{ border: 'none' }}
              />
            </a>
          </noscript>
          <object className="tableauViz" style={{ display: 'none' }}>
            <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
            <param name="embed_code_version" value="3" />
            <param name="site_root" value="" />
            <param
              name="name"
              value="Covid-19PhilippinesFlattenthecurvebreakthechain/Covid-19PHDashboard"
            />
            <param name="tabs" value="no" />
            <param name="toolbar" value="no" />
            <param
              name="static_image"
              value="https://public.tableau.com/static/images/Co/Covid-19PhilippinesFlattenthecurvebreakthechain/Covid-19PHDashboard/1.png"
            />
            <param name="device" value="phone" />
            <param name="animate_transition" value="yes" />
            <param name="display_static_image" value="yes" />
            <param name="display_spinner" value="yes" />
            <param name="display_overlay" value="yes" />
            <param name="display_count" value="yes" />
          </object>
        </div>
      </div>
    </BaseTemplate>
  );
};

export default CovidPage;
